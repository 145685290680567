import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BlogPreview from "../components/blogPreview"

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const BlogCategoryTemplate = ({ data, location, pageContext }) => {
  const pageTitle = pageContext.id.replaceAll("/", "")
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes

  const filteredPosts = [];
  posts.map((post) => {
    let postCategories = post.frontmatter.categories;
    postCategories?.split(";").map((categoryName) => {
        if (categoryName !== null && categoryName === pageTitle) {
            filteredPosts.push(post);
        }
    })
  })

  return (
    <Layout location={location} title={siteTitle}>
        <Container maxWidth="xl" sx={{ my: 4 }}>
            <Typography color="inherit" align="center" variant="h2" marked="center" style={{ zIndex: 1 }} gutterBottom>
                { pageTitle } Blogs
            </Typography>
            <div>
                <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'} }}>
                    {filteredPosts ? filteredPosts.map(post => {
                        return (
                        <BlogPreview key={post.fields.slug} post={post} />
                        )
                    }) : null}
                </Box>
            </div>
        </Container>
    </Layout>
  )
}

export default BlogCategoryTemplate

export const pageQuery = graphql`
  query Categories (
    $id: String!
  ) {
    site {
      siteMetadata {
        title
        author {
          name
          summary
        }
        social {
          twitter
        }
      }
    },
    allMarkdownRemark(filter: { frontmatter: { categories: { regex: $id } } }) {
        nodes {
            excerpt
            fields {
                slug
            }
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                previewImage
                categories
            }
        }
    }
  }
`
